.dash_box {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
}

/* sidebar styles */

.sidebar_nav {
  text-align: start;
  padding: 0;
}

.sidebar_nav_icon {
  padding: 0.6rem;
  background-color: #f5f7ff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.sidebar_nav_element {
  position: relative;
}

.sidebar_nav_element:hover,
.sidebar_auth_element:hover {
  cursor: pointer;
}

.sidebar_nav_element_selected .sidebar_nav_label {
  font-weight: 700;
}

.sidebar_nav_element_selected::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute; /* Position relative to the parent .element */
  left: 0; /* Position on the left side */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
  width: 4px; /* Width of the border */
  height: 50%; /* Height of the border */
  background-color: #57bec0; /* Color of the border */
  /* You can also use border-left instead of background-color for border styling */
  /* border-left: 2px solid #000; */
}

.sidebar_nav_element_selected svg path {
  fill: #fff;
}

.sidebar_nav_element_selected .sidebar_nav_icon {
  background-color: #57bec0;
}

.sidebar_auth_element_help {
  color: #57bec0;
}

.sidebar_head {
  height: 12%;
}

.sidebar_elements {
  height: 88%;
}

/* sidebar behaviour styles */

.content_box {
  flex: 1;
}

@media (min-width: 1440px) {
  .sidebar_collapsed {
    flex: 0 0 6%;
    overflow-x: hidden;
  }

  .sidebar_expanded {
    flex: 0 0 10%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .sidebar_collapsed {
    flex: 0 0 8%;
    overflow-x: hidden;
  }
}

@media (min-width: 475px) and (max-width: 576px) {
  .sidebar_collapsed {
    flex: 0 0 17%;
    overflow-x: hidden;
    height: 100%;
    position: fixed;
  }

  .content_box {
    padding-left: 17% !important;
  }
}

@media (max-width: 475px) {
  .sidebar_collapsed {
    flex: 0 0 20%;
    height: 100%;
    position: fixed;
  }

  .content_box {
    flex: 1;
    padding-left: 20% !important;
  }
}

.sidebar_collapsed .sidebar_nav_label,
.sidebar_collapsed .sidebar_auth_element_label {
  display: none !important ;
}

/* navbar styles */

@media (min-width: 576px) {
  .navbar_box {
    background-color: #fff;
    height: 10%;
  }
}

@media (max-width: 576px) {
  .dashboard_content {
    padding-top: 30%;
  }

  .navbar_box {
    position: fixed;
    z-index: 100;
    background-color: #fff;
    height: 10%;
    width: 83%;
  }
}

@media (min-width: 1200px) {
  .searchbar {
    width: 30%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .searchbar {
    width: 40%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .searchbar {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .searchbar {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .sidebar_toggler {
    padding: 0.6rem;
    background-color: #f5f7ff;
    border-radius: 8px;
  }

  .sidebar_offcanvas_toggler {
    display: none;
  }
}

@media (max-width: 768px) {
  .sidebar_offcanvas_toggler {
    padding: 0.6rem;
    background-color: #f5f7ff;
    border-radius: 8px;
  }

  .sidebar_toggler {
    display: none;
  }

  .offcanvas {
    height: 100%;
    width: 40%;
    border-top-right-radius: 2rem;
    transition: none;
  }

  .fade {
    transition: none;
  }

  .offcanvas-backdrop.show {
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgba(68, 71, 144, 0.39);
    backdrop-filter: blur(5px);
  }
}

/* search bar styles */

.input-group-text {
  background: #f5f7ff !important;
}

.searchbar_input::placeholder {
  color: rgba(31, 35, 73, 0.35);
  /* background: #f5f7ff !important; */
}

.form-control:focus {
  border-color: #dee2e6 !important;
  box-shadow: none;
}

.user_icon {
  border-radius: 106px;
  padding: 0.3rem;
  background: #65c9ce23;
}

/* content styles */

.content_box {
  background-color: #fff;
}

@media (min-width: 576px) {
  .dashboard_content {
    border-top-left-radius: 2rem;
    background-color: #f2f5ff;
    height: 90%;
  }
}

@media (max-width: 576px) {
  .dashboard_content {
    border-top-left-radius: 2rem;
    background-color: #f2f5ff;
  }
}

::-webkit-scrollbar {
  width: 0;
}
