/* general layout styles */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");

.maindash_container {
  height: 100%;
  overflow-y: scroll;
}

@media (min-width: 1200px) {
  .maindash_details_box {
    width: 75%;
  }

  .maindash_calander_outerbox {
    width: 24%;
    height: 99%;
  }
}

@media (max-width: 1200px) {
  .maindash_details_box {
    width: 100%;
  }

  .maindash_calander_outerbox {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .maindash_calander_outerbox {
    margin-top: 2rem;
  }
}

.dash_header {
  color: #1f2349;
  font-weight: 700;
  text-align: start;
  height: 15%;
}

@media (min-width: 992px) {
  .dash_content {
    height: 85%;
  }

  .main_dash_detail {
    height: 47%;
  }
}

@media (max-width: 992px) {
  .main_dash_detail {
    height: auto;
  }

  .next_event_outerbox {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 768px) {
  .next_event_outerbox {
    margin-top: 1.5rem;
  }
}

/* user details styles */

.user_details_innerbox {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 9px 38px 0px #cdd2ff;
  height: 100%;
}

.user_name {
  font-weight: 700;
  text-transform: capitalize;
  color: #1f2349;
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .user_detail {
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .user_detail {
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
  }
}

@media (min-width: 992px) {
  .user_image {
    position: absolute;
    background: #d0dfff;
    top: -23%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.5rem solid #fff;
    border-radius: 50%;
    border-bottom: none;
  }
}

@media (max-width: 576px) {
  .user_details_innerbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .user_name {
    font-size: 1.5rem;
  }

  .user_image {
    position: absolute;
    background: #d0dfff;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.5rem solid #fff;
    border-radius: 50%;
    border-bottom: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .user_details_innerbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .user_name {
    font-size: 1.5rem;
  }

  .user_image {
    position: absolute;
    background: #d0dfff;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.5rem solid #fff;
    border-radius: 50%;
    border-bottom: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .user_details_innerbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .user_name {
    font-size: 1.5rem;
  }

  .user_image {
    position: absolute;
    background: #d0dfff;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.5rem solid #fff;
    border-radius: 50%;
    border-bottom: none;
  }
}

.user_detail_text,
.user_detail_text a {
  color: #1f2349;
}

.user_details_button {
  border-radius: 30px;
  background: #1f2349;
  box-shadow: 0px 10px 10px 0px rgba(68, 71, 144, 0.25);
  color: #fff;
  width: 93%;
  padding: 1rem 0;
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .user_details_innerbox {
    padding-bottom: 2rem;
  }

  .user_details_button {
    padding: 0.5rem !important;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .user_details_button {
    padding: 0.5rem !important;
  }
}

@media (min-width: 1440px) {
  .user_details_innerbox {
    padding-bottom: 1rem;
  }
}

@media (min-width: 1200px) and (min-height: 850px) and (max-height: 900px) {
  .user_details_button {
    margin-top: 1rem;
  }
}

@media (min-width: 1200px) and (min-height: 900px) {
  .user_details_button {
    margin-top: 2rem;
  }
}

.user_details_innerbox {
  margin-top: 7%;
  padding-top: 2.5rem;
  position: relative;
  height: 92%;
}

/* sales amount styles */

.sales_amount_date {
  color: #bdcbfc;
  text-align: start;
}

.sales_amount_innerbox {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 9px 38px 0px #cdd2ff;
  height: 100%;
  padding-top: 1.5rem;
  overflow: hidden;
}

.sales_amount_value {
  color: #1f2349;
}

.sales_amount_header,
.sales_amount_value {
  padding: 0 1.5rem;
}

.sales_amount_chart {
  max-width: 100%;
  overflow: hidden;
  max-height: 100px;
}

@media (min-width: 1440px) {
  .currency {
    color: #57bec0;
    align-self: flex-end;
    font-size: 2rem;
    font-weight: 700;
    font-family: Inter !important;
  }

  .before_decimal {
    font-size: 3rem;
  }

  .after_decimal {
    font-size: 1.5rem;
    margin-top: 0.7rem;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .currency {
    color: #57bec0;
    align-self: flex-end;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: Inter !important;
  }

  .before_decimal {
    font-size: 2.2rem;
  }

  .after_decimal {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .currency {
    color: #57bec0;
    align-self: flex-end;
    font-size: 2rem;
    font-weight: 700;
    font-family: Inter !important;
  }

  .before_decimal {
    font-size: 3rem;
  }

  .after_decimal {
    font-size: 1.5rem;
    margin-top: 0.7rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .currency {
    color: #57bec0;
    align-self: flex-end;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: Inter !important;
  }

  .before_decimal {
    font-size: 4rem;
  }

  .after_decimal {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .orders_outerbox {
    order: 5;
  }

  .next_event_outerbox {
    order: 4;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .currency {
    color: #57bec0;
    align-self: flex-end;
    font-size: 2rem;
    font-weight: 700;
    font-family: Inter !important;
  }

  .before_decimal {
    font-size: 3rem;
  }

  .after_decimal {
    font-size: 1.5rem;
    margin-top: 0.7rem;
  }
}

@media (max-width: 576px) {
  .currency {
    color: #57bec0;
    align-self: flex-end;
    font-size: 2.5rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
    font-family: Inter !important;
  }

  .before_decimal {
    font-size: 4rem;
  }

  .after_decimal {
    font-size: 2rem;
    margin-top: 1rem;
  }
}

/* tickets chart styles */

.tickets_chart_date {
  color: #bdcbfc;
  text-align: start;
}

.tickets_chart_innerbox {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 9px 38px 0px #cdd2ff;
  height: 100%;
  padding: 1.5rem;
}

.tickets_chart_header {
  height: 25%;
  margin-bottom: 1rem;
}

@media (min-width: 1440px) {
  .tickects_chart_container {
    width: 100% !important;
    height: 85% !important;
  }

  .tickects_chart_box {
    height: 75%;
    width: 70%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .tickects_chart_container {
    margin-top: 15%;
    width: 100% !important;
    height: 70% !important;
  }

  .tickects_chart_box {
    height: 75%;
    width: 70%;
    margin: 0 auto;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .tickects_chart_container {
    margin-top: 20%;
    width: 100% !important;
    height: 65% !important;
  }

  .tickects_chart_box {
    height: 75%;
    width: 70%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .tickects_chart_container {
    margin-top: 20%;
    width: 100% !important;
    height: 70% !important;
  }

  .tickects_chart_box {
    height: 75%;
    width: 80%;
    margin: 0 auto;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .tickects_chart_container {
    margin-top: 20%;
    width: 100% !important;
    height: 70% !important;
  }

  .tickects_chart_box {
    height: 75%;
    width: 100%;
  }
}

.tickets_chart_labels {
  width: 100% !important;
  height: 15% !important;
}

.tickects_chart-legend {
  display: none;
}

.tickects_chart-innertext {
  display: none;
}

.tickects_chart-arcs-path {
  opacity: 1 !important;
}

.tickects_chart-arcs-path:hover {
  filter: drop-shadow(0px 0px 25px rgba(26, 36, 36, 0.2)) !important;
}

/* orders styles */

.orders_header {
  height: 15%;
  margin-bottom: 0.5rem;
}

.orders_list {
  height: 85%;
}

.orders_innerbox {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 9px 38px 0px #cdd2ff;
  height: 100%;
  padding: 1.5rem;
}

.orders_date {
  color: #bdcbfc;
  text-align: start;
}

@media (min-width: 1440px) {
  .orders_list_element_detail {
    color: #1f2349;
    font-weight: 700;
    font-size: 0.9rem;
    text-transform: capitalize;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .orders_list_element_detail {
    color: #1f2349;
    font-weight: 700;
    font-size: 0.7rem;
    text-transform: capitalize;
  }

  .orders_list_element_icon svg {
    width: 35px;
    height: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .orders_list_element_detail {
    color: #1f2349;
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: capitalize;
  }

  .orders_list_element_icon svg {
    width: 35px;
    height: 30px;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .orders_list_element_detail {
    color: #1f2349;
    font-weight: 700;
    text-transform: capitalize;
  }

  .orders_list_element_icon svg {
    width: 35px;
    height: 30px;
  }
}

@media (max-width: 576px) {
  .orders_list_element_detail {
    color: #1f2349;
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: capitalize;
  }

  .orders_list_element_icon svg {
    width: 35px;
    height: 30px;
  }
}

.orders_list_element {
  border-bottom: #e9eeff solid 0.857px;
}

.orders_list_element:last-of-type {
  border: none;
}

/* next event styles */

.next_event_innerbox {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 9px 38px 0px #cdd2ff;
  height: 100%;
  padding: 1.5rem;
}

.next_event_banner {
  height: 50%;
  border-radius: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0.5rem 0;
}

.next_event_name {
  text-transform: capitalize;
  text-align: start;
  font-weight: 700;
  font-size: 1.5rem;
  color: #fff;
  padding-left: 1.5rem;
}

.next_event_date {
  text-align: start;
  color: #70e4e7;
  font-weight: 700;
  padding-left: 1rem;
}

.next_event_sales {
  height: 44%;
}

.next_event_sales_tickets,
.next_event_sales_revenue {
  width: 45%;
  border-radius: 30px;
}

.next_event_sales_tickets {
  border: 1px solid #57bec0;
  opacity: 0.8;
  background: #fff;
  box-shadow: 0px 13px 9px -4px rgba(101, 200, 206, 0.28);
  height: 100%;
}

@media (min-width: 1200px) {
  .next_event_sales_tickets_progressbar {
    max-width: 50%;
    height: 50%;
    margin: 0 auto;
  }
  .next_event_sales_revenue {
    border: 1px solid #5056eb;
    opacity: 0.8;
    background: #fff;
    box-shadow: 0px 13px 9px -4px rgba(63, 70, 255, 0.3);
    height: 100%;
    padding: 1.5rem 1rem 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .next_event_sales_tickets_progressbar {
    max-width: 40%;
    height: 50%;
    margin: 0 auto;
  }

  .next_event_sales_revenue {
    border: 1px solid #5056eb;
    opacity: 0.8;
    background: #fff;
    box-shadow: 0px 13px 9px -4px rgba(63, 70, 255, 0.3);
    height: 100%;
    padding: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .next_event_sales_tickets_progressbar {
    max-width: 50%;
    height: 50%;
    margin: 0 auto;
  }

  .next_event_sales_revenue {
    border: 1px solid #5056eb;
    opacity: 0.8;
    background: #fff;
    box-shadow: 0px 13px 9px -4px rgba(63, 70, 255, 0.3);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .next_event_sales_tickets_progressbar {
    max-width: 30%;
    height: 50%;
    margin: 0 auto;
  }

  .next_event_sales_tickets_progressbar_label {
    font-size: 0.6rem !important;
  }

  .next_event_sales_revenue {
    border: 1px solid #5056eb;
    opacity: 0.8;
    background: #fff;
    box-shadow: 0px 13px 9px -4px rgba(63, 70, 255, 0.3);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .next_event_sales_tickets_progressbar {
    max-width: 30%;
    height: 50%;
    margin: 0 auto;
  }

  .next_event_sales_tickets_progressbar_label {
    font-size: 0.6rem !important;
  }

  .next_event_sales_revenue {
    border: 1px solid #5056eb;
    opacity: 0.8;
    background: #fff;
    box-shadow: 0px 13px 9px -4px rgba(63, 70, 255, 0.3);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.next_event_sales_tickets_progressbar_label {
  font-size: 0.7rem;
  color: #444790;
  text-transform: capitalize;
}

.next_event_sales_revenue {
  color: #444790;
}

.next_event_sales_revenue_afterDec {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 700;
}

.next_event_sales_revenue_beforeDec {
  font-weight: 700;
  font-size: 1.3rem;
}

.next_event_sales_revenue_Currency {
  font-size: 2rem;
  line-height: 2rem;
  margin-top: 0.5rem;
  font-weight: 700;
}

.next_event_sales_revenue_label {
  font-size: 0.8rem;
}

/* calendar styles */

/* .maindash_calander_outerbox {
} */

.maindash_calendar_innerbox {
  padding: 1.5rem;
  height: 100%;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 9px 38px 0px #cdd2ff;
}

@media (max-width: 576px) {
  .rmdp-wrapper,
  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker div {
    max-width: 100%;
    width: 100% !important;
  }

  .maindash_calendar {
    width: 100%;
  }

  .maindash_calendar_next_event_box {
    width: 100%;
  }

  .maindash_calendar_innerbox_divider {
    width: 1px;
    background: #e9eeff;
    height: 95%;
  }

  .maindash_calendar_next_event_details_box {
    justify-content: space-between;
    padding-left: 1rem;
  }

  .maindash_calendar_next_event_details {
    padding: 0.5rem;
  }

  .maindash_calendar_divider {
    width: 20%;
    margin-left: 2%;
  }

  .maindash_calendar_next_event_details {
    width: 60%;
  }

  .maindash_calendar_next_event_details svg {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .rmdp-wrapper,
  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker div {
    max-width: 100%;
    width: 100% !important;
  }

  .maindash_calendar {
    width: 49%;
  }

  .maindash_calendar_next_event_box {
    width: 49%;
  }

  .maindash_calendar_innerbox_divider {
    width: 1px;
    background: #e9eeff;
    height: 95%;
  }

  .maindash_calendar_next_event_details_box {
    justify-content: space-between;
    padding-left: 1rem;
  }

  .maindash_calendar_next_event_details {
    padding: 0.5rem;
  }

  .maindash_calendar_divider {
    width: 10%;
    margin-left: 2%;
  }

  .maindash_calendar_next_event_details svg {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .rmdp-wrapper,
  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker div {
    max-width: 100%;
    width: 100% !important;
  }

  .maindash_calendar {
    width: 49%;
  }

  .maindash_calendar_next_event_box {
    width: 49%;
  }

  .maindash_calendar_innerbox_divider {
    width: 1px;
    background: #e9eeff;
    height: 95%;
  }

  .maindash_calendar_next_event_details_box {
    justify-content: space-between;
    padding-left: 1rem;
  }

  .maindash_calendar_next_event_details {
    padding: 0.5rem;
  }

  .maindash_calendar_divider {
    width: 20%;
    margin-left: 5%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .rmdp-wrapper {
    max-width: 100%;
    width: 100% !important;
    height: 80%;
  }

  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker div {
    max-width: 100%;
    width: 100% !important;
  }

  .maindash_calendar {
    width: 49%;
  }

  .maindash_calendar_next_event_box {
    width: 49%;
  }

  .maindash_calendar_innerbox_divider {
    width: 1px;
    background: #e9eeff;
    height: 95%;
  }

  .maindash_calendar_next_event_details_box {
    width: 100%;
    justify-content: space-between;
    padding-left: 1rem;
  }

  .maindash_calendar_next_event_details {
    padding: 0.5rem;
  }

  .maindash_calendar_divider {
    width: 20%;
    margin-left: 5%;
  }
}

@media (min-width: 1200px) {
  .rmdp-wrapper {
    height: 60%;
    max-width: 100%;
    width: 100% !important;
  }

  .rmdp-top-class,
  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker div {
    max-width: 100%;
    width: 100% !important;
  }

  .maindash_calendar_next_event_box {
    height: 35%;
    position: relative;
  }

  .maindash_current_events_box {
    overflow-y: scroll;
    height: 50%;
  }
}

.maindash_calendar_innerbox_header {
  text-align: start;
  font-weight: 700;
}

.rmdp-shadow {
  box-shadow: none !important;
}

.rmdp-header-values {
  color: #444790 !important;
  font-weight: 700 !important;
  font-size: 1.1rem;
}

.rmdp-arrow-container {
  border-radius: 106px;
  background: #889cff6a !important;
  align-items: center;
}

.rmdp-arrow {
  margin: 0 !important;
}

.rmdp-day.rmdp-selected span:not(.highlight),
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-range)
  span:hover {
  background-color: transparent !important;
  color: #abb4d4;
}

@media (min-width: 1680px) {
  .rmdp-week {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1440px) and (max-width: 1680px) {
  .rmdp-week {
    margin-bottom: 0.8rem;
  }
}

@media (min-width: 1440px) {
  .sd,
  .rmdp-week-day {
    font-size: 1.3rem !important;
    font-weight: 700;
  }

  .maindash_calendar_next_event_details {
    padding: 1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .maindash_calendar_next_event_details {
    padding: 0.5rem 0.3rem !important;
  }

  .maindash_calendar_next_event_hour {
    width: 20%;
  }

  .maindash_calendar_next_event_details {
    width: 70%;
  }

  .maindash_calendar_divider {
    width: 10%;
  }
}

@media (min-width: 1440px) {
  .maindash_calendar_next_event_details {
    padding: 0.5rem !important;
  }

  .maindash_calendar_next_event_hour {
    width: 20%;
  }

  .maindash_calendar_next_event_details {
    width: 60%;
  }

  .maindash_calendar_divider {
    width: 20%;
  }
}

.rmdp-day:not(.rmdp-range) {
  color: #abb4d4 !important;
}

.rmdp-range .sd:hover {
  background-color: transparent !important;
}

.rmdp-range {
  background-color: #65c8ce !important;
}

.rmdp-day.rmdp-today span {
  background-color: #0074d9 !important;
  color: #000 !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  color: #abb4d4 !important;
}

.maindash_calendar_next_event_date {
  color: #65c8ce;
  font-weight: 700;
  text-transform: capitalize;
  text-align: end;
}

.maindash_calendar_next_event_hour {
  color: #7225ff;
  font-weight: 700;
  text-transform: capitalize;
  text-align: start;
}

.maindash_calendar_next_event_details {
  border-radius: 10px;
  background: #f0e8ff;
}

.maindash_calendar_divider {
  background: rgba(114, 37, 255, 0.5);
  height: 1px;
}

.maindash_calendar_next_event_name {
  color: #7225ff;
  font-weight: 400;
  font-size: 1rem;
}
